import { DARolService } from '../../../../services/context/core-proccess-context';
import types from '../../common/mutation-types';

const actions = {

    /**
     *  Función que permite listar los roles de usuario
     * @param {*} param0 
     */
    async getRoles({commit}) {
        let response = await DARolService.getRoles();
        if(response.success) {
            commit(types.SET_ROLES, response.data)
        }
    }

}

export default actions