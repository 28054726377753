import Vue from 'vue';
import Vuex from 'vuex';
import CORE from './modules/core/index';

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
      
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      CORE
    }
  })
