import { DAC_PATOLOGIC_SECTION } from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que permite listar el catalogo se secciones patologicas
     * @returns 
     */
    async getPatologicSections() {
        try {
            return DACore.get(DAC_PATOLOGIC_SECTION);
        } catch (error) {
            return error.response.data;
        }
    }
}