import types from '../common/mutation-types';

const mutations = {
    [types.SET_ADD_CONFIG] (state, payload) {
        state.saveConfigurations = payload ? payload : []
    },    
    [types.SET_GET_CONFIG] (state, payload) {
        state.getConfigurations = payload ? payload : []
    },   
    [types.SET_UPD_CONFIG] (state, payload) {
        state.editConfigurations = payload ? payload : []
    }, 
    [types.SET_CONFING_EXCEPTION] (state, payload) {
        if(payload) {
            state.hasErrorConfiguration = payload
        }else {
            state.hasErrorConfiguration.error = false
        }
    },
}


export default mutations