import { DAC_USER_SAVE, DAC_USER_LIST, DAC_USER_UPDATE, DAC_USER_GET } from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que permite listar los usuarios
     * @returns 
     */
    async getUsers() {
        try {
            return DACore.get(DAC_USER_LIST);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que recupera los datos de un usuario
     * @param {*} id 
     * @returns 
     */
    async getUser(id) {
        try {
            return DACore.get(DAC_USER_GET + id);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función para realizar el alta de usuarios o personal
     * @param {*} data Modelo de datos de usuario
     * @returns 
     */
    async save(data) {
        try {
            return DACore.post(DAC_USER_SAVE, data);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite actualizar los datos del usuario
     * @param {*} id ID del usuario
     * @param {*} data Modelo de datos del usuario
     * @returns 
     */
    async update(id, data) {
        try {
            return DACore.put(DAC_USER_UPDATE + id, data);
        } catch (error) {
            return error.response.data;
        }
    }
}