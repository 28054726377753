
import store from '../../store/index';
import types from '../../store/modules/common/mutation-types';
import modules from '../../store/modules/common/module-types';
 
export const actions = {
    unauthorized: async () => {
        store.commit(modules.AUTH+'/'+types.SET_AUTH_UNAUTHORIZED, true, {root: true})
    },
    error: () => {
        window.location.href = '/500'
    },
    notFound: () => {
        window.location.href = '/404'
    },
    default: (error) => {
        return Promise.reject(error)
    }
}

export const options = {

    401: actions.unauthorized,

    500: actions.error,

    'default': actions.default
}