import { DAC_CONFIG_SAVE, DAC_CONFIG_GET,DAC_CONFIG_UPDATE} from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que guardar la configuracion
     * 
     * @returns 
     */
    async saveConfiguration(data) {
        try {
            return DACore.post(DAC_CONFIG_SAVE,data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que edita la configuracion
     * 
     * @returns 
     */
    async editConfiguration(data) {
        try {
            return DACore.put(DAC_CONFIG_UPDATE,data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que obtiene la configuracion
     * 
     * @returns 
     */
    async getConfiguration() {
        try {
            return DACore.get(DAC_CONFIG_GET);
        } catch (error) {
            return error.response.data;
        }
    },
}