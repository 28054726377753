<template>
  <div class="h-full bg-center bg-cover" :style="`background-image: url('`+bgImage+`')`">
    <header class="fixed overflow-hidden top-0 inset-x-0 z-50 shadow-md">
    <nav class="bg-white flex justify-between align-middle  py-2 px-2 ">
    <div class="flex self-center align-middle justify-self-center">
        <v-app-bar-nav-icon @click="openMenu = !openMenu">
            <template>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="35" height="35" viewBox="0 0 24 24" stroke-width="3" stroke="#B46B15" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="4" y1="6" x2="20" y2="6" />
                        <line x1="4" y1="12" x2="20" y2="12" />
                        <line x1="4" y1="18" x2="20" y2="18" />
                        </svg>
            </template>
        </v-app-bar-nav-icon >
      
    </div>
    <div>
          <div class="sm:h-14 lg:h-14 sm:w-48 lg:w-48 ">
            <div class="h-full w-auto bg-contain bg-center" :style="`background-image: url('`+logoOrrico+`')`"></div>
          </div>
    </div>
    </nav>
    </header>

    <v-dialog
      v-model="openMenu"
      transition="dialog-top-transition"
      max-width="100%"
      style="margin: 0px; border-radius: 0px;"
      class="ma-0 top-0"
      hide-overlay
      id="dialogmenu"
      content-class="elevation-0"
      
    >
    <div class="w-full bg-primary-300 h-16 origin-top mt-5" id="menu" :class="{'block opacity-100': openMenu, 'hidden mt-0 opacity-50': !openMenu}">
      <ul
          class="
            h-16
            text-base text-gray-700
            flex
            justify-center items-center divide-x-2 divide-primary-900"
        >
        <span>
          <router-link :to="'/'" id="idHome" >
            <span class="sm:p-1 lg:p-4 py-2 block sm:text-sm lg:text-base text-primary-900 hover:text-primary-1000 cursor-pointer">INICIO</span>
          </router-link>
        </span>
          <span>
            <router-link :to="'/admin'" id="iduser" >
              <span class="sm:p-2 lg:p-4 py-2  block sm:text-sm lg:text-base text-primary-900 hover:text-primary-1000 cursor-pointer">ADMON</span>
            </router-link>
          </span>
          <span>
            <router-link :to="'/px/all'" id="idpaciente" >
              <span class="sm:p-2 lg:p-4 py-2  block sm:text-sm lg:text-base text-primary-900 hover:text-primary-1000 cursor-pointer">PACIENTES</span>
            </router-link>
          </span>
          <span>
            <router-link :to="'/px/dates'" id="idpaciente" >
              <span class="sm:p-2 lg:p-4 py-2  block sm:text-sm lg:text-base text-primary-900 hover:text-primary-1000 cursor-pointer">CITAS</span>
            </router-link>
          </span>
          <span>
            <span class="sm:p-2 lg:p-4 py-0 block text-primary-900 hover:text-primary-1000">
              <svg xmlns="http://www.w3.org/2000/svg" @click="onLogout" class="icon icon-tabler icon-tabler-download transform -rotate-90 cursor-pointer" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#B46B15" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                <polyline points="7 11 12 16 17 11" />
                <line x1="12" y1="4" x2="12" y2="16" />
              </svg>
            </span>
          </span>
        </ul>
    </div>

    </v-dialog>
        
    <div v-if="$route.meta.headertitle" class="py-3 flex justify-center mt-20">
        <label class="text-terciary-500 font-sans font-normal sm:text-xl lg:text-3xl tracking-wider">{{ $route.meta.headertitle ? $route.meta.headertitle : '' }}</label>
    </div>
        
    <da-re-login :show="isRelogin" v-on:auth="isRelogin = false"></da-re-login>
    <div class="mt-10 pb-16">
      <slot></slot>
    </div>

      <div class="absolute inset-x-0 bottom-0 text-center mb-4 text-lg font-medium text-primary-900 cursor-pointer underline underline-offset-8" @click="$router.push('/privacity')">
        Aviso de privacidad
      </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fnTypes from '../../store/modules/common/fn-types';
import moduleTypes from '../../store/modules/common/module-types';
import ReLogin from '../../views/Auth/ReLogin.vue';
import VuexMixin from '../../mixins/VuexMixin';

export default {
    mixins:[VuexMixin],
    data: function() {
        return {
            menu: [],
            bgImage:    require('@/assets/login/doBackGround.png'),
            logoOrrico: require('@/assets/login/doLogotypeSmall.png'),
            openMenu: false,
            isRelogin: false
        }
    },
    components: {
      'da-re-login': ReLogin
    },
    computed: {
        ...mapGetters(moduleTypes.AUTH, {
            isAuthorized: fnTypes.isAuthorized,
            user: fnTypes.getUser,
            unauthorized: fnTypes.unauthorized,
            refreshtoken: fnTypes.refreshtoken,
            isRefresingToken: fnTypes.isRefresingToken,
        }),
        currentRoute: function() {
          return this.$route.name
        }
    },

    methods: {
        ...mapActions(moduleTypes.AUTH, [
            fnTypes.logout, fnTypes.getRefreshtoken
        ]),
        onLogout: async function() {
            await this.logout();
            if(!this.isAuthorized) {
                this.$router.push('/auth/login')
            }
        },
        onBlur: function() {
          console.log('BLUR');
          
        }
    },
    watch: {
      currentRoute: function() {
          this.openMenu = false;
      },
      unauthorized: async function() {
        if(this.unauthorized && this.refreshtoken) {
          await this.getRefreshtoken()
        }else {
          if(this.isRefresingToken == false && !this.refreshtoken) {
              this.isRelogin = true;
          }
        }
      }
    }
}
</script>

<style >
.header, .v-toolbar__content {
    align-items: center;
    display: flex;
    position: relative;
    z-index: 0;
    width: 100%;
}

.v-dialog {
  border-radius: 0px;
  margin: 0px;
  overflow-y: auto;
  pointer-events: auto;
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  outline: none;
}

</style>

<style scoped>

.v-dialog {
  box-shadow: 0 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%)
}

.v-dialog__content {
  align-items: start;
  margin-top: 3.2rem;
}

</style>