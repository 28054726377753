import { DAUserService } from '../../../services/context/core-proccess-context';
import types from '../common/mutation-types';

const actions = {

    /**
     *  Función que permite listar los usuarios
     * @param {*} param0 
     */
    async getUsers({commit}) {
            let response = await DAUserService.getUsers();
            if(response.success) {
                commit(types.SET_USERS, response.data)
            }
    },

    /**
     * Función que permite recuperar unh usuario
     * @param {*} param0 
     * @param {*} id ID del usuario
     */
    async getUser({commit}, id) {
            let response = await DAUserService.getUser(id);
            if(response.success) {
                commit(types.SET_USER, response.data)
            }
    },

    /**
     * Función para realizar el alta de usuarios o personal
     * @param {*} data Modelo de datos de usuario
     * @returns 
     */
    async save({commit}, data) {
            let response = await DAUserService.save(data);
            if(response.success) {
                commit(types.SET_RESPONSE_SAVE, true)
            }
    },
    /**
     * Función que permite actualizar los datos del usuario
     * @param {*} id ID del usuario
     * @param {*} data Modelo de datos del usuario
     * @returns 
     */
    async update({dispatch, commit},  {data: data, id: id}) {
            commit(types.SET_ERROR_SERVICE, false)
            let response = await DAUserService.update(id, data);
            if(response.success) {
                dispatch('getUsers')
            }else {
                commit(types.SET_ERROR_SERVICE, true)
            }
    }

}

export default actions