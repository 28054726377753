import { DAC_PX_SAVE, DAC_PX_BY_ID, DAC_PX_UPDATE, DAC_PX_DELETE } from '../../common/apiurl';
import { DACore } from '../../config/axios.context';

export default {

    /**
     * Función que permite dar de alta un paciente
     * @returns 
     */
    async save(data) {
        try {
            return DACore.post(DAC_PX_SAVE, data);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite actualizar los datos del paciente
     * @param {*} data Datos del paciente
     * @returns 
     */
    async update(data) {
        try {
            return DACore.put(DAC_PX_UPDATE, data);
        } catch (error) {
            return error.response.data;
        }
    },


    /**
     * Función que permite recuperar el px por id
     * @param {*} id ID del px
     * @returns 
     */
    async getById(id) {
        try {
            return DACore.get(DAC_PX_BY_ID + id);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite eliminar de forma logica al paciente(PX)
     * @param {*} data 
     * @returns 
     */
    async remove(data) {
        try {
            return DACore.delete(DAC_PX_DELETE, data);
        } catch (error) {
            return error.response.data;
        }
    }


}