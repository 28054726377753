

import DAToothModel from '../../../models/odontogram/DATooth.model';
import types from '../common/mutation-types';

const mutations = {
    [types.SET_ODONTOGRAM] (state, payload) {
            state.odontogram = payload ? payload : false
    },
    [types.SET_ODONTOGRAM_TOOTH] (state, payload) {
            state.tooth = payload ? payload : new DAToothModel()
    },
    [types.SET_ODONTOGRAM_TOOTH_ERROR] (state, payload) {
            state.hasError = payload ? payload : false
    },
    [types.SET_ODONTOGRAM_TYPE_TOOTH] (state, payload) {
            state.typeTooth = payload ? payload : []
    },
    [types.SET_ODONTOGRAM_TYPE_CODE] (state, payload) {
            state.codetypetooth = payload ? payload : ''
    }
}

export default mutations