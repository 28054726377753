import Vue from 'vue';
import { DAAuthService } from '../../../services/context/core-proccess-context';
import { toSHA256 } from '../../../libs/crypto';
import { getTimestamp } from '../../../libs/time';
import DAAuthModel from '../../../models/auth/DAAuthModel';
import types from '../common/mutation-types';
import DAHasErrorModel from '../../../models/error/DAHasErrorModel';
import modules from '../common/module-types';
import DARefreshTokenModel from '../../../models/auth/DARefreshTokenModel';

const actions = {

    /**
     * Función que permite realizar el inicio de sesión en el sistema
     * @param {*} param0 
     * @param {*} credentials 
     */
    async login({commit, dispatch}, credentials) {

            const {pwd, param, refresh} = credentials;
            let authModel = new DAAuthModel()
            authModel.param = param
            authModel.pwd = pwd
            authModel.secretkey = process.env.VUE_APP_SECRET_KEY
            authModel.timestamp = getTimestamp()
            authModel.signature = toSHA256(authModel.secretkey+authModel.timestamp);
            authModel.refresh = refresh

            let response = await DAAuthService.login(authModel);
            if(response.success) {
                commit(types.SET_TOKEN, response.data.token)
                commit(types.SET_REFRESH_TOKEN, response.data.refresh_token)
                commit(types.SET_AUTH_LOGIN_EXCEPTION, new DAHasErrorModel() )
                dispatch(modules.CORE+'/rollback', {}, {root: true})
            }
            else {
                commit(types.SET_AUTH_LOGIN_EXCEPTION, new DAHasErrorModel(true, response.message) )
            }
    },

    /**
     * Función que permite mandar un código OTP
     * @param {*} data {PHONE: 961}
     */
    async sendOtp({commit},data) {
        let response = await DAAuthService.sendOtp(data);
        if(response.success) {
            commit(types.SET_AUTH_SENDOTP, response.data)
            commit(types.SET_AUTH_OTP_EXCEPTION, new DAHasErrorModel() )
        }
        else {
            commit(types.SET_AUTH_SENDOTP,false)
            commit(types.SET_AUTH_OTP_EXCEPTION, new DAHasErrorModel(true, response.message) )
        }
    },

    /**
     * Función que permite validar un código OTP
     * @param {*} data {code: 12345, telephone: 96155555}
     */
    async validateOtp({commit},data) {
        let response = await DAAuthService.validateOtp(data);
        if(response.success) {
            commit(types.SET_AUTH_VALIDATEOTP, response.data)
            commit(types.SET_AUTH_VOTP_EXCEPTION, new DAHasErrorModel() )
        }
        else {
            commit(types.SET_AUTH_VALIDATEOTP,false)
            commit(types.SET_AUTH_VOTP_EXCEPTION, new DAHasErrorModel(true, response.message) )
        }
    },

    /**
     * Función que permite actualizar la contraseña
     * @param {*} data {otp: 12345, telephone: 96155555, password: '123456'}
     */
    async updatePwd({commit},data) {
        let response = await DAAuthService.updatePwd(data);
        if(response.success) {
            commit(types.SET_AUTH_UPDATEPWD, response.data)
            commit(types.SET_AUTH_PWD_EXCEPTION, new DAHasErrorModel() )
        }
        else {
            commit(types.SET_AUTH_UPDATEPWD,false)
            commit(types.SET_AUTH_PWD_EXCEPTION, new DAHasErrorModel(true, response.message) )
        }
    },

    /**
     * Función que permite recuperar el token de la sesión
     * @param {*} param0 
     */
    async getToken({commit}) {
        const token =  Vue.prototype.$session? Vue.prototype.$session.get('token') : null;
        const refreshtoken =  Vue.prototype.$session? Vue.prototype.$session.get('refreshtoken') : null;
        if(token) {
            commit(types.SET_TOKEN, token)
            commit(types.SET_REFRESH_TOKEN, refreshtoken)
        }else {
            commit(types.SET_TOKEN, null)
            commit(types.SET_REFRESH_TOKEN, null)
        }
    },
    /**
     * Función que permite actualizar el token de sesión
     * @param {*} param0 Datos de refresh token
     */
    async getRefreshtoken({state, commit, dispatch}) {
        commit(types.SET_AUTH_REFRESING_TOKEN, true)
         if(!state.refreshtoken) { dispatch('logout', null) }
         let response = await DAAuthService.refreshToken(new DARefreshTokenModel(state.refreshtoken));
        if(response.success) {
            commit(types.SET_AUTH_UNAUTHORIZED, false)
            commit(types.SET_TOKEN, response.data.token)
            commit(types.SET_REFRESH_TOKEN, response.data.refresh_token)
            dispatch(modules.CORE+'/rollback', {}, {root: true})
        }else {
            commit(types.SET_TOKEN, null)
            commit(types.SET_REFRESH_TOKEN, null)
            dispatch('logout', null);
            window.location.href = '/'
        }
        commit(types.SET_AUTH_REFRESING_TOKEN, false)
    },

    /**
     * Función que permite cerrar la sesión del usuario
     * @param {*} param0 
     */
    async logout({commit}) {
        Vue.prototype.$session.destroy();
        commit(types.SET_TOKEN, null)
    }
}

export default actions