const getters = {
    isAuthorized:   state => state.token ? true : false,
    getUser:        state => state.user,
    hasError:       state => state.hasError,
    hasErrorOTP:    state => state.hasErrorOTP,
    hasErrorVOTP:   state => state.hasErrorVOTP,
    hasErrorPWD:    state => state.hasErrorPWD,
    unauthorized:   state => state.unauthorized,
    sendOtp:        state => state.isOtpSend,
    validateOtp:    state => state.isValidateOtp, 
    updatePwd:      state => state.isPwdUpdated, 
    refreshtoken:      state => state.refreshtoken, 
    isRefresingToken:      state => state.isRefresingToken, 
    
}

export default getters