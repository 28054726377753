export default {

    //ROOT
    currentAction:      'currentAction',
    ActionWrapper:      'ActionWrapper',

   // AUTH
    login:              'login',
    logout:             'logout',
    getToken:           'getToken',
    isAuthorized:       'isAuthorized',
    unauthorized:       'unauthorized',
    sendOtp:            'sendOtp',
    validateOtp:        'validateOtp',
    updatePwd:          'updatePwd',
    hasErrorOTP:        'hasErrorOTP',
    hasErrorVOTP:       'hasErrorVOTP',
    hasErrorPWD:        'hasErrorPWD',
    refreshtoken:       'refreshtoken',
    getRefreshtoken:    'getRefreshtoken',
    isRefresingToken:   'isRefresingToken',

    //USER
    getUser:            'getUser',
    getUsers:           'getUsers',
    save:               'save',
    isSave:             'isSave',
    update:             'update',

    //---- CATALOGS ----//
    //-ROLES
    getRoles:           'getRoles',
    //-FAMILY-PATH
    getFamilyPath:      'getFamilyPath',
    //-DISEASES
    getDiseases:        'getDiseases',
    //-PATOLOGIC
    getSections:        'getSections',
    getPatological:     'getPatological',
    getPatologicInfo:   'getPatologicInfo',

    //DASHBOARD
    getBirthdays:       'getBirthdays',
    getPatients:        'getPatients',

    // PX
    getById:            'getById',
    getPx:              'getPx',
    id_person:          'id_person',
    remove:             'remove',
    uploadProfile:      'uploadProfile',
    
    // GLOBAL
    hasError:           'hasError',
    hasErrorInService:  'hasErrorInService',

    hasErrorPhoto:      'hasErrorPhoto',
    getTypeFiles:       'getTypeFiles',
    savePhoto:          'savePhoto',
    getPhotos:          'getPhotos',
    deletePhoto:        'deletePhoto',
    initPhotos:        'initPhotos',
    
    //Tracing Monitoring
    saveTracing:        'saveTracing',
    updTracing:        'updTracing',
    getTracing:         'getTracing',
    deleteTracing:      'deleteTracing',
    getAllTracing:      'getAllTracing',
    getAllPxTreatment:  'getAllPxTreatment',

    //ODONTOGRAMA
    getOdontogram:      'getOdontogram',
    odontogram:         'odontogram',
    getTooth:           'getTooth',
    onSaveTooth:        'onSaveTooth',
    getTypeTooth:       'getTypeTooth',
    codetypetooth:      'codetypetooth',
    initOdontogram:      'initOdontogram',

    //CONDITIONS
    getConditions:      'getConditions',
    conditions:         'conditions',

    //Other Treatment
    saveOTreatment:     'saveOTreatment',
    editOTreatment:     'editOTreatment',
    getOTreatment:      'getOTreatment',
    deleteOTreatment:   'deleteOTreatment',

    //TRATAMENT
    getTratament:       'getTratament',
    getTratamentEdit: 'getTratamentEdit',
    getTratamentDetail: 'getTratamentDetail',
    saveTratament: 'saveTratament',
    hasErrorTreatment: 'hasErrorTreatment',

    //Configurations
    saveConfiguration:  'saveConfiguration',
    editConfiguration:  'editConfiguration',
    getConfiguration:   'getConfiguration',

    //CATEGORY
    categories:   'categories',
    getCategories:   'getCategories',
}