import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import Vuetify from 'vuetify';
import router from './router';
import store from './store';
import '@mdi/font/css/materialdesignicons.css'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSession from 'vue-session';
import '@/libs/filters';
import '@/libs/directive';
import VueSignature from 'vue-signature-pad';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.use(VueViewer)
var options = {
  persist: true
}
Vue.use(VueSignature)
Vue.use(VueSession, options)
Vue.use(VueSweetalert2);
Vue.use(Vuetify)
library.add(fas);

Vue.config.productionTip = false

//COMPONENTES GLOBALES
Vue.component('f-icon', FontAwesomeIcon);
Vue.component("lottie-animation", LottieAnimation);
Vue.component("da-button-primary",  require('./core/components/form/buttons/DAPrimaryButton.vue').default);
Vue.component("da-button-secondary",  require('./core/components/form/buttons/DASecondaryButton.vue').default);
Vue.component("da-button-gray",  require('./core/components/form/buttons/DAGrayButton.vue').default);
Vue.component("da-datatable",  require('./core/components/form/table/DADatatable.vue').default);
Vue.component("da-text-field-primary",  require('./core/components/form/input/DATextFieldPrimary.vue').default);
Vue.component("da-text-area-primary",  require('./core/components/form/input/DATextAreaPrimary.vue').default);
Vue.component("da-header-title",  require('./core/components/form/header/DAHeaderTitle.vue').default);
Vue.component("da-button-actions",  require('./core/components/form/buttons/DAActionsButton.vue').default);
Vue.component("da-button-900",  require('./core/components/form/buttons/DAPrimary900Button.vue').default);
Vue.component("da-dialog",  require('./core/components/form/dialog/DADialog.vue').default);
Vue.component("da-dialog-confirm",  require('./core/components/form/dialog/components/DADialogconfirm.vue').default);
Vue.component("da-date-picker",  require('./core/components/form/menu/DADatePicker.vue').default);
Vue.component("da-date-picker-field",  require('./core/components/form/menu/DADatePickerField.vue').default);
Vue.component("da-signature-pad",  require('./core/components/global/signature/DASignaturePad.vue').default);
Vue.component("da-cropper",  require('./core/components/global/files/DACropper.vue').default);
Vue.component("da-loader",  require('./core/components/form/loader/loader.vue').default);
Vue.component("da-scoped-column",  require('./core/components/form/table/DAScopedColumn.vue').default);
//LAYOUTS
Vue.component('default-layout', require('./core/layouts/default.vue').default);
Vue.component('admin-layout', require('./core/layouts/admin.vue').default);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
