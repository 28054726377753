import auth from '../../store/modules/auth/index';

export default {

    /**
     * Permite obtener el token del storage
     * @returns 
     */
    getToken() {
        const token = auth.state().token;
        let headers = {headers: {'Authorization': token}}
        return headers
    }
}