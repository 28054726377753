import axiosHelper from "./axios.helper";
import context from "./axios.config";
import httpstatus from "../../utils/common/httpstatus";
import { ResultData } from "../../models/global/resultdata.model";

export default {

     /**
     * Realiza una petición de tipo get a la url indicada
     * @param {String} url url 
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns Object
     */
    async get(url) {
        let response = new ResultData()
        try {
            let result = await context.core.get(url, axiosHelper.getToken());
            if(result && result.status == httpstatus.SUCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
            response = {...error.response.data, success: false}
        }
        return response
    },

     /**
     * Realiza una petición de tipo post a la url indicando su carga util
     * @param {String} url url a enviar
     * @param {any} payload Carga Util
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns Object
     */
    async post(url, payload = {}) {
        let response = new ResultData()
        try {
            let result = await context.core.post(url, payload, axiosHelper.getToken());
            if(result && result.status == httpstatus.SUCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
            response = {...error.response.data, success: false}
        }
        return response
    },

     /**
     * Realiza una petición de tipo put a la url indicando su carga util
     * @param {String} url url a enviar
     * @param {any} payload Carga Util
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns 
     */
    async put(url, payload = {}) {
        let response = new ResultData()
        try {
            let result = await context.core.put(url, payload, axiosHelper.getToken());
            if(result && result.status == httpstatus.SUCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
            response = {...error.response.data, success: false}
        }
        return response
    },

    /**
     * Permite eliminar registros
     * @param {*} url url a enviar
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     */
    async delete(url, payload = {}) {
        let response = new ResultData()
        try {
            let result = await context.core.delete(url, {data: payload,  ...axiosHelper.getToken() });
            if(result && result.status == httpstatus.SUCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
            response = {...error.response.data, success: false}
        }
        return response
    }
}