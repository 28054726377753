<template>
  <da-dialog v-model="show" :persist="true">
    <template #body>
         <!-- INICIA FORM LOGIN  -->
         <div class="sm:w-92 md:w-96 lg:w-128 h-128 bg-white rounded-lg shadow-xl animate-fade-in-up">
                <div class="text-center pt-8 pb-8">
                  <h1 class="font-semibold text-primary-700 text-5xl md:text-5xl lg:text-6xl">HOLA.</h1>
                  <h1 class="font-thin text-primary-700 text-2xl md:text-3xl lg:text-3xl">BIENVENIDA(O) {{ user && user.name ? user.name : '' }}</h1>
                  <h1 class="font-thin text-gray-400 text-xl">Para continuar, por favor inicia sesión</h1>
                </div>
                <div class="w-full flex justify-center pl-4 pr-4">
                  <div class="sm:mt-0 md:mt-2 w-80">
                    
                    <da-text-field-primary 
                      :icon="'mdi-phone'"
                      :label="'Teléfono'"
                      :value="$v.auth.param"
                      :error="$v.auth.param.$invalid && $v.auth.param.$anyDirty && $v.auth.param >= $v.auth.param.$params.maxLength.max" 
                      v-model="$v.auth.param.$model"
                      number
                      @keypress.enter.native="onLogin"
                      :rules="[ ($v.auth.param.$anyDirty ? $v.auth.param.phonePattern : true) || 'Máximo 10 caracteres', !hasError.error || hasError.message ]"
                    />

                    <da-text-field-primary
                      ref="textpwd"
                      class="mt-5" 
                      :icon="'mdi-key'"
                      :label="'Contraseña'"
                      :value="$v.auth.pwd"
                      :error="$v.auth.pwd.$invalid && $v.auth.pwd.$anyDirty" 
                      v-model="$v.auth.pwd.$model"
                      :vtype="showPWD ? 'text': 'password'"
                      @keypress.enter.native="onLogin"
                      :rules="[!hasError.error || hasError.message]"
                    >
                    <template slot="append-icon">
                            <v-icon @click="showPWD = !showPWD">{{!showPWD ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                    </template>
                    </da-text-field-primary>

                    <v-checkbox
                      v-model="$v.auth.refresh.$model"
                      label="Mantener la sesión iniciada"
                      color="gray"
                      hide-details
                      class="mt-0 pt-0"
                    ></v-checkbox>

                    <div class="mt-8 text-center gap-y-2 grid">
                      <da-button-primary @click.native="onLogin" :label="'ENTRAR'" r2xl swpr class="w-full py-2"></da-button-primary>
                      <da-button-secondary @click.native="onLogout" :label="'SALIR'" r2xl swpr class="w-full py-2"></da-button-secondary>
                    </div>

                  </div>
                </div>

              </div>
    </template>
  </da-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
import DAAuthModel from '../../models/auth/DAAuthModel';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { phonePattern } from '../../utils/common/regex'

export default {
    mixins: [validationMixin],
    props: {
        show: Boolean
    },
    data: function() {
        return {
            auth: new DAAuthModel(),
            showPWD: false
        }
    },
    validations: {
    auth: {
        param: {
          required,
          phonePattern,
          maxLength: maxLength(10)
        },
        pwd: {
          required: requiredIf( function() {
            return this.auth.param != "" ? true : false
          })
        },
        refresh: {
          required
        }
      }
    },
    computed: {
        ...mapGetters(moduleTypes.AUTH, {
            hasError: fnTypes.hasError,
            user: fnTypes.getUser,
            isAuthorized: fnTypes.isAuthorized,
        }),
    },
    methods: {
        ...mapActions(moduleTypes.AUTH, [
            fnTypes.login, fnTypes.logout
        ]),

        onLogin: async function() {

          this.$v.$touch();
          if(!this.$v.$anyError) {
            await this.login(this.auth)
            if(this.isAuthorized) {
                this.$emit('auth');
            }
          }
      },
      onLogout: async function() {
        await this.logout();
        if(!this.isAuthorized) {
            this.$router.push('/auth/login')
        }
      }
    }
    
}
</script>

<style lang="scss" scoped>

</style>