<template>
     <div class="flex justify-between items-center">
           <strong class="md:hidden"> <slot name="header"></slot> </strong> <slot name="value"></slot>
     </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>