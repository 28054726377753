export default [
    {
        path:'/px',
        name:'patient',
        component: () => import('../../views/Patient/Patient.vue'),
        meta: {title: 'Px - Expedient',  requireAuth: true, layout: 'admin'},
        children: [
            {
                path:'/px/new',
                name:'NewPx',
                component: () => import('../../views/Patient/PatientNew.vue'),
                meta: {title: 'Px - Nuevo',  requireAuth: true, layout: 'admin',  headertitle: 'Alta de Paciente'},
            },
            {
                path:'/px/history/:id',
                name:'ShowPx',
                component: () => import('../../views/Patient/PatientShow.vue'),
                props: route => ({id: route.params.id}),
                meta: {title: 'Px - Expediente',  requireAuth: true, layout: 'admin',  headertitle: ''},
            },
            {
                path:'/px/all',
                name:'AllPatients',
                component: () => import('../../views/Patient/Patients.vue'),
                props: route => ({id: route.params.id}),
                meta: {title: 'Px - Buscador',  requireAuth: true, layout: 'admin',  headertitle: 'Pacientes'},
            }
        ]

    },
    {
        path:'/px/treatmentplan',
        name:'PlanTratamiento',
        component: () => import('../../views/Patient/Sections/TreatmentPlan.vue'),
        meta: {title: 'Plan de Tratamiento - DOrrico',  requireAuth: true, layout: 'admin'},

    },
    {
        path:'/px/dates',
        name:'Citas',
        component: () => import('../../views/dates/dates.vue'),
        meta: {title: 'Proximas Citas - DOrrico',  requireAuth: true, layout: 'admin', headertitle: 'Citas'},

    },
    {
        path:'/px/monitoringplan',
        name:'PlanSeguimiento',
        component: () => import('../../views/Patient/Sections/MonitoringPlan.vue'),
        meta: {title: 'Plan de Seguimiento - DOrrico',  requireAuth: true, layout: 'admin'},

    },
    {
        path:'/px/odontogram',
        name:'Odontograma',
        component: () => import('../../views/Patient/Sections/Odontogram.vue'),
        meta: {title: 'Odontograma - DOrrico',  requireAuth: true, layout: 'admin'},

    },
    {
        path:'/px/photoManager',
        name:'Fotos',
        component: () => import('../../views/Patient/Sections/PhotoManager.vue'),
        meta: {title: 'Administrador de Foto - DOrrico',  requireAuth: true, layout: 'admin'},

    },
    {
        path:'/px/radiographyManager',
        name:'Radiografias',
        component: () => import('../../views/Patient/Sections/RadioManager.vue'),
        meta: {title: 'Gestor de Radiografias - DOrrico',  requireAuth: true, layout: 'admin'},

    }
    
]
