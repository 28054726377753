import DAToothModel from "../../../models/odontogram/DATooth.model"

const state = () => ({
    odontogram: [],
    tooth: new DAToothModel(),
    hasError: false,
    typeTooth: [],
    codetypetooth: ''
})

export default state