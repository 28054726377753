import Vue from 'vue';
import DAHasErrorModel from '../../../models/error/DAHasErrorModel';

const state = () => ({
    token: Vue.prototype.$session ? Vue.prototype.$session.get('token'): '',
    refreshtoken: Vue.prototype.$session ? Vue.prototype.$session.get('refreshtoken'): '',
    user:           null,
    security:       null,
    hasError:       new DAHasErrorModel(),
    hasErrorOTP:    new DAHasErrorModel(),
    hasErrorVOTP:   new DAHasErrorModel(),
    hasErrorPWD:    new DAHasErrorModel(),
    unauthorized:   false,
    isOtpSend:      {},
    isValidateOtp:  {},
    isPwdUpdated:   {},
    isRefresingToken: false
})

export default state