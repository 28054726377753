import { DAC_BIRTHDAY_LIST, DAC_PX_SEARCHER } from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que permite obtener un listado con los paciente  que cumplen años el mes actual
     * @returns 
     */
    async getBirthdays() {
        try {
            return DACore.get(DAC_BIRTHDAY_LIST);
        } catch (error) {
            return error.response.data;
        }
    },

        /**
     * Función que permite obtener un listado con los paciente  que cumplen con el filtro de busqueda
     * @returns 
     */
        async getPatients(data) {
            try {
                return DACore.post(DAC_PX_SEARCHER,data);
            } catch (error) {
                return error.response.data;
            }
        }
}