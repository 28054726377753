export default {
    CORE:           'CORE',
    AUTH:           'CORE/AUTH',
    USER:           'CORE/USER',
    CATALOG:        'CORE/CATALOGS',
    ROLE:           'CORE/CATALOGS/ROLE',
    FAMILYPATH:     'CORE/CATALOGS/FAMILYPATH',
    CONDITION:      'CORE/CATALOGS/CONDITION',
    CATEGORY:      'CORE/CATALOGS/CATEGORY',
    DASHBOARD:      'CORE/DASHBOARD',
    PX:             'CORE/PX',
    DISEASE:        'CORE/CATALOGS/DISEASE',
    PATOLOGIC:      'CORE/CATALOGS/PATOLOGIC',
    PHOTO:          'CORE/PHOTO',
    MONITORING:     'CORE/MONITORING',
    ODONTOGRAM:     'CORE/ODONTOGRAM',
    TREATMENT:      'CORE/TREATMENT',
    CONFIGURATION:  'CORE/CONFIGURATION'
}