import { DAC_FILE, DAC_FILE_PHOTO, DAC_GET_PHOTOS, DAC_DELETE_PHOTO} from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que lista los tipos de fotos
     * 
     * @returns 
     */
    async getTypeFiles() {
        try {
            return DACore.get(DAC_FILE);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que guarda una foto
     * 
     * @returns 
     */
    async savePhoto(data) {
        try {
            return DACore.post(DAC_FILE_PHOTO,data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que obtiene las fotos
     * 
     * @returns 
     */
    async getPhotos(data) {
        try {
            return DACore.get(DAC_GET_PHOTOS + data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que Elimina una foto
     * 
     * @returns 
     */
        async deletePhoto(data) {
            try {
                return DACore.delete(DAC_DELETE_PHOTO,data);
            } catch (error) {
                return error.response.data;
            }
        },
}