import { DACategoryService } from '../../../../services/context/core-proccess-context';
import types from '../../common/mutation-types';

const actions = {

    /**
     *  Función que permite listar los tipos de familiares
     * @param {*} param0 
     */
    async getCategories({commit}) {
        let response = await DACategoryService.getCategories();
        if(response.success) {
            commit(types.SET_CATEGORIES, response.data)
        }
    }

}

export default actions