export default {
    // ROOT
    SET_CURRENT_ACTION:         'SET_CURRENT_ACTION',
    
    // AUTH
    SET_TOKEN:                  'SET_TOKEN',
    SET_REFRESH_TOKEN:          'SET_REFRESH_TOKEN',
    SET_AUTH_LOGIN_EXCEPTION:   'SET_AUTH_LOGIN_EXCEPTION',
    SET_AUTH_UNAUTHORIZED:      'SET_AUTH_UNAUTHORIZED',
    SET_AUTH_SENDOTP:           'SET_AUTH_OTP',
    SET_AUTH_VALIDATEOTP:       'SET_AUTH_VALIDATEOTP',
    SET_AUTH_UPDATEPWD:         'SET_AUTH_UPDATEPWD',
    SET_AUTH_OTP_EXCEPTION:     'SET_AUTH_OTP_EXCEPTION',
    SET_AUTH_VOTP_EXCEPTION:    'SET_AUTH_VOTP_EXCEPTION',
    SET_AUTH_PWD_EXCEPTION:     'SET_AUTH_PWD_EXCEPTION',
    SET_AUTH_REFRESING_TOKEN:   'SET_AUTH_REFRESING_TOKEN',

    //USER
    SET_USERS:                  'SET_USERS',
    SET_RESPONSE_SAVE:          'SET_RESPONSE_SAVE',
    SET_ERROR_SERVICE:          'SET_ERROR_SERVICE',
    SET_USER:                   'SET_USER',

    // CATALOG - ROLE
    SET_ROLES:                  'SET_ROLES',
    
    // CATALOG - PATH
    SET_FAMILY_PATH:             'SET_FAMILY_PATH',

    // CATALOG - PATOLOGIC-SECTIONS
    SET_PATOLOGIC_SECTIONS:     'SET_PATOLOGIC_SECTIONS',
    SET_PATOLOGICAL_QA:         'SET_PATOLOGICAL_QA',

    // DASHBOARTD
    SET_BIRTHDAYS:              'SET_BIRTHDAYS',
    SET_PATIENTS:               'SET_PATIENTS',

    //PX
    SET_PX:                     'SET_PX',
    SET_PX_ID_PERSON:           'SET_PX_ID_PERSON',

    //PHOTO
    SET_TYPE_PHOTO:             'SET_TYPE_PHOTO',
    SET_PHOTO_CAT_EXCEPTION:    'SET_PHOTO_CAT_EXCEPTION',
    SET_PHOTO_SAVE:             'SET_PHOTO_SAVE',
    SET_PHOTO_GET:              'SET_PHOTO_GET',
    SET_PHOTO_DELETE:           'SET_PHOTO_DELETE',

    //MONITORING
    SET_ADD_MONITORING:         'SET_ADD_MONITORING',
    SET_UPD_MONITORING:         'SET_UPD_MONITORING',
    SET_GET_MONITORING:         'SET_GET_MONITORING',
    SET_DELETE_MONITORING:      'SET_DELETE_MONITORING',
    SET_GET_ALL_MONITORING:     'SET_GET_ALL_MONITORING',
    SET_GET_ALL_PX_TREATMENT:   'SET_GET_ALL_PX_TREATMENT',
    SET_MONITORING_EXCEPTION:   'SET_MONITORING_EXCEPTION',
    //ODONTOGRAMA
    SET_ODONTOGRAM:             'SET_ODONTOGRAM',
    SET_ODONTOGRAM_TOOTH:       'SET_ODONTOGRAM_TOOTH',
    SET_ODONTOGRAM_TOOTH_ERROR: 'SET_ODONTOGRAM_TOOTH_ERROR',
    SET_ODONTOGRAM_TYPE_TOOTH:  'SET_ODONTOGRAM_TYPE_TOOTH',
    SET_ODONTOGRAM_TYPE_CODE:   'SET_ODONTOGRAM_TYPE_CODE',
    
    //CONDITIONS
    SET_CONDITIONS:             'SET_CONDITIONS',

    //CATEGORIES
    SET_CATEGORIES:             'SET_CATEGORIES',

    //OTREATMENTS
    SET_ADD_OTREATMENT:         'SET_ADD_OTREATMENT',
    SET_UPD_OTREATMENT:         'SET_UPD_OTREATMENT',
    SET_GET_OTREATMENT:         'SET_GET_OTREATMENT',
    SET_DELETE_OTREATMENT:      'SET_DELETE_OTREATMENT',
    SET_OTREATMENT_EXCEPTION:   'SET_OTREATMENT_EXCEPTION',

    //TRATAMENT
    SET_TRATAMENT:          'SET_TRATAMENT',
    SET_TRATAMENT_EDIT:     'SET_TRATAMENT_EDIT',
    SET_TRATAMENT_DETAIL:   'SET_TRATAMENT_DETAIL',
    SET_TREATMENT_EXCEPTION:   'SET_TREATMENT_EXCEPTION',

    //CONFIGURATIONS
    SET_ADD_CONFIG:             'SET_ADD_CONFIG',
    SET_GET_CONFIG:             'SET_GET_CONFIG',
    SET_UPD_CONFIG:             'SET_UPD_CONFIG',
    SET_CONFING_EXCEPTION:      'SET_CONFING_EXCEPTION',
}