import { DAC_ADD_OTREATMENT, DAC_GET_OTREATMENT, DAC_DELETE_OTREATMENT,DAC_UPD_OTREATMENT, DAC_TRATAMENT_GET, DAC_TRATAMENT_SAVED} from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que guarda una Tratamiento [OTROS]
     * 
     * @returns 
     */
    async saveOTreatment(data) {
        try {
            return DACore.post(DAC_ADD_OTREATMENT,data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que edita una Tratamiento [OTROS]
     * 
     * @returns 
     */
        async editOTreatment(data) {
            try {
                return DACore.put(DAC_UPD_OTREATMENT,data);
            } catch (error) {
                return error.response.data;
            }
        },

    /**
     * Función que obtiene los tratamientos [OTROS]
     * 
     * @returns 
     */
    async getOTreatment(data) {
        try {
            return DACore.get(DAC_GET_OTREATMENT + data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que elimina una tratamiento [OTROS]
     * 
     * @returns 
     */
    async deleteOTreatment(data) {
        try {
            return DACore.delete(DAC_DELETE_OTREATMENT,data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener el tratamiento
     * @param {*} id ID del paciente
     * @returns 
     */
    async getTratament(id) {
        try {
            return DACore.get(DAC_TRATAMENT_GET + id);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite actualizar los datos del tratamiento
     * @param {*} data Modelo del tratamiento
     * @returns 
     */
    async saveTratament(data) {
        try {
            return DACore.post(DAC_TRATAMENT_SAVED, data);
        } catch (error) {
            return error.response.data;
        }
    }

}