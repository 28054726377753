<template>
    <div>
    <VueSignaturePad
        id="signature"
        width="100%"
        height="300px"
        ref="signaturePad"
        class="signature-success"
        :options="signaturepadOptions"
    />
    <small v-if="isSignatureEmpty" class="error-text">* La firma es requerida</small>
    <div class="flex justify-start py-5 md:px-0 lg:px-5 gap-5">
        <da-button-gray         :label="'Deshacer'" @click.native="undo" rmd class="py-1 md:px-1 lg:px-5 font-light"></da-button-gray>
        <da-button-secondary    :label="'Limpiar'" @click.native="clear" rmd class="py-1 md:px-1 lg:px-5 font-light"></da-button-secondary>
        <da-button-primary      :label="'Aplicar'"  @click.native="save" rmd class="py-1 md:px-1 lg:px-5 font-light"></da-button-primary>
    </div>

</div>
</template>

<script>
export default {
    props: {
        required: Boolean
    },
    data() {
        return {
            signaturepadOptions: {
                penColor: "#000000",
                scaleToDevicePixelRatio: true
            },
            isSignatureEmpty: false
        }
    },
    methods: {
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
        },
        save() {
            this.isSignatureEmpty = false;
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if(isEmpty && this.required) {this.isSignatureEmpty = isEmpty; return}
          
            this.$emit('signed', data)
        }
    }

}
</script>

<style scoped>

#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #F8C73D, #E7AC18);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

</style>