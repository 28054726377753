import DAToothConfigModel from '../../../models/odontogram/DAToothConfig.model';
import { DAOdontogramService } from '../../../services/context/core-proccess-context';
import types from '../common/mutation-types';
import fnTypes from '../common/fn-types';

const actions = {

    async initOdontogram({dispatch}, {id: id, code: code}) {
        dispatch(fnTypes.getTypeTooth, {});
        dispatch(fnTypes.getOdontogram, {id: id, code: code});
    },

    /**
     *  Función que permite obtener los datos del odontograma
     * @param {*} param0 
     */
    async getOdontogram({commit}, {id: id, code: code}) {
            let response = await DAOdontogramService.getOdontogram(id, code)
            if(response.success) {
                commit(types.SET_ODONTOGRAM, response.data.teeth)
                commit(types.SET_ODONTOGRAM_TYPE_CODE, response.data.code_cat_type_tooth)
            }
    },

    /**
     * Función que permite obtener los datos de un diente
     * @param {*} param0 
     * @param {*} param1 
     */
    async getTooth({commit}, {id: id, id_person: id_person}) {
            let response = await DAOdontogramService.getTooth(id, id_person)
            if(response.success) {
                if(response.data.config.length <= 0) {
                    response.data.config = [ new DAToothConfigModel() ]
                }
                commit(types.SET_ODONTOGRAM_TOOTH, response.data)
            }
    },

    /**
     * Función que permite obtener el catalogo de tipos de odontogramas
     * @param {*} param0 
     */
    async getTypeTooth({commit}) {
            let response = await DAOdontogramService.getTypeTooth()
            if(response.success) {
                commit(types.SET_ODONTOGRAM_TYPE_TOOTH, response.data)
            }
    },

    /**
     *  Función que permite guardar los datos y configuración de un diente
     * @param {*} param0 
     * @param {*} data 
     */
    async save({commit}, data) {
            let response = await DAOdontogramService.save(data)
            if(response.success) {
                commit(types.SET_ODONTOGRAM_TOOTH_ERROR, false)
            }
    },

    /**
     * Funcion que permite actualizar los datos de dien te y su config
     * @param {*} data  Modelo de los dientes
     * @returns 
     */
    async update({commit}, data) {
            let response = await DAOdontogramService.update(data)
            if(!response.success) {
                commit(types.SET_ODONTOGRAM_TOOTH_ERROR, true)
            }
    },

    /**
     * Función para guardar o actualizar
     * @param {*} param0 
     * @param {*} data 
     */
    async onSaveTooth({dispatch}, data) {
            if(data.id_odontogram != '' && data.id_odontogram != null) {
                dispatch('update', data)
            }else {
                dispatch('save', data)
            }
    }
}

export default actions