import Vue from 'vue';
import VueRouter from 'vue-router';
import dashboard from './routes/dashboard';
import admin from './routes/admin';
import auth from './routes/auth';
import patient from './routes/patient';
import error from './routes/error';
import legal from './routes/legal';

Vue.use(VueRouter)

const routes = [
        { 
            path: '/',
            name: 'home', 
            redirect: { name: 'dashboard' },
        },
        ...auth,
        ...dashboard,
        ...admin,
        ...patient,
        ...error,
        ...legal,
        { path: "*", component: () => import('@/views/error/404.vue') }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  function isAuthorized() {
    let session = Vue.prototype.$session.get('token');
    return session == undefined || !session ? false : true;
  }


  router.beforeEach((to, from, next) => {
     const requiredAuth  = to.matched.some(record => record.meta.requireAuth);
     if (requiredAuth && to.name !== 'login' && !isAuthorized()){
          next({ name: 'auth' })
      } 
  
    if(to.name === 'login' && isAuthorized()){
        next({ name: 'dashboard'})
    } else{
      next();
    }   
  });
  
  

const DEFAULT_TITLE = 'DOrrico';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  })
})

export default router


  
  