import AUTH         from "../auth/index"
import USER         from "../user/index"
import CATALOGS     from "../catalogs/index"
import DASHBOARD    from "../dashboard/index"
import PX           from "../px/index"
import PHOTO        from "../photo/index"
import MONITORING   from "../monitoring/index"
import ODONTOGRAM   from "../odontogram/index"
import TREATMENT    from "../treatment/index"
import CONFIGURATION       from "../configuration/index"
import state        from "./state"
import actions      from "./actions"
import getters      from "./getters"
import mutations    from "./mutations"


export default {
    namespaced: true,
    state:      state,
    mutations:  mutations,
    actions:    actions,
    getters:    getters,
    modules: {
        AUTH,
        USER,
        CATALOGS,
        DASHBOARD,
        PX,
        PHOTO,
        MONITORING,
        ODONTOGRAM,
        TREATMENT,
        CONFIGURATION
    }
}