import { DAC_AUTH_LOGIN,DAC_AUTH_SENDOTP,DAC_AUTH_VALIDATEOTP, DAC_AUTH_UPDATEPWD, DAC_AUTH_REFRESH_TOKEN } from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que permite realizar el login del usuario
     * @param {*} data Modelo de datos
     * @returns 
     */
    async login(data) {
        try {
            return DACore.post(DAC_AUTH_LOGIN, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite mandar un código OTP para modificar contraseña
     * @param {*} data Modelo de datos
     * @returns 
     */
    async sendOtp(data) {
        try {
            return DACore.post(DAC_AUTH_SENDOTP, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite validar el código OTP
     * @param {*} data Modelo de datos
     * @returns 
     */
    async validateOtp(data) {
        try {
            return DACore.post(DAC_AUTH_VALIDATEOTP, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite validar el código OTP
     * @param {*} data Modelo de datos
     * @returns 
     */
    async updatePwd(data) {
        try {
            return DACore.put(DAC_AUTH_UPDATEPWD, data);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite realizar el refrescado del token de sesión
     * @param {*} data Modelo de datos del refresh token
     * @returns 
     */
    async refreshToken(data) {
        try {
            return DACore.post(DAC_AUTH_REFRESH_TOKEN, data);
        } catch (error) {
            return error.response.data;
        }
    }
}