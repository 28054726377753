import { render, staticRenderFns } from "./DAHeaderTitle.vue?vue&type=template&id=6a2aa00b&"
import script from "./DAHeaderTitle.vue?vue&type=script&lang=js&"
export * from "./DAHeaderTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports