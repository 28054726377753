import { DAConfigService } from '../../../services/context/core-proccess-context';
import DAHasErrorModel from '../../../models/error/DAHasErrorModel';
import types from '../common/mutation-types';

const actions = {
    /**
     *  Función que permite Agregar una configuracion
     * @param {*} param0 
     */
    async saveConfiguration({commit},data) {
        let response = await DAConfigService.saveConfiguration(data);
        if(response.success) {
            commit(types.SET_ADD_CONFIG, response.data)
            commit(types.SET_CONFING_EXCEPTION, new DAHasErrorModel() )
        }
        else {
            commit(types.SET_ADD_CONFIG,[])
            commit(types.SET_CONFING_EXCEPTION, new DAHasErrorModel(true, response.message) )
        }
    },

    /**
     *  Función que lista la configuracion
     * @param {*} param0 
     */
    async getConfiguration({commit}) {
        let response = await DAConfigService.getConfiguration();
        if(response.success) {
            commit(types.SET_GET_CONFIG, response.data)
            commit(types.SET_CONFING_EXCEPTION, new DAHasErrorModel() )
        }
        else {
            commit(types.SET_GET_CONFIG,[])
            commit(types.SET_CONFING_EXCEPTION, new DAHasErrorModel(true, response.message) )
        }
    },

    /**
     *  Función que edita la configuracion
     * @param {*} param0 
     */
    async editConfiguration({commit},data) {
        let response = await DAConfigService.editConfiguration(data);
        if(response.success) {
            commit(types.SET_UPD_CONFIG, response.data)
            commit(types.SET_CONFING_EXCEPTION, new DAHasErrorModel() )
        }
        else {
            commit(types.SET_UPD_CONFIG,[])
            commit(types.SET_CONFING_EXCEPTION, new DAHasErrorModel(true, response.message) )
        }
    },

}

export default actions