// import { DAC_CONDITIONS_GET } from '../../common/apiurl';
// import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que permite listar las categorías del almacen
     * @returns 
     */
    async getCategories() {
        try {
            // return DACore.get(DAC_CONDITIONS_GET);
            let data =  {
                success: true,
                data: [
                    {name: 'Material 1', date: new Date(), active: true},
                    {name: 'Material 2', date: new Date(), active: true},
                    {name: 'Material 3', date: new Date(), active: true},
                    {name: 'Material 4', date: new Date(), active: false},
                ]
            }
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
}