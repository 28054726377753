import types from '../common/mutation-types';
import Vue from 'vue';
import decode from 'jwt-decode';

const mutations = {
    [types.SET_TOKEN] (state, payload) {
        if(payload) {
            state.token = payload
            state.user = decode(payload)
            state.unauthorized = false
            Vue.prototype.$session.set('token', payload)
        }else {
            state.user = null
            state.token = null
        }
    },
    [types.SET_REFRESH_TOKEN] (state, payload) {
        if(payload) {
            state.refreshtoken = payload
            Vue.prototype.$session.set('refreshtoken', payload)
        }else {
            state.refreshtoken = null
        }
    },
    [types.SET_AUTH_LOGIN_EXCEPTION] (state, payload) {
        if(payload) {
            state.hasError = payload
        }else {
            state.hasError.error = false
        }
    },
    [types.SET_AUTH_UNAUTHORIZED] (state, payload) {
        if(payload) {
            state.unauthorized = payload
        }else {
            state.unauthorized = false
        }
    },
    [types.SET_AUTH_SENDOTP] (state, payload) {
        state.isOtpSend = payload ? payload : false
    },
    [types.SET_AUTH_VALIDATEOTP] (state, payload) {
        state.isValidateOtp = payload ? payload : false
    },
    [types.SET_AUTH_UPDATEPWD] (state, payload) {
        state.isPwdUpdated = payload ? payload : false
    },
    [types.SET_AUTH_OTP_EXCEPTION] (state, payload) {
        if(payload) {
            state.hasErrorOTP = payload
        }else {
            state.hasErrorOTP.error = false
        }
    },
    [types.SET_AUTH_VOTP_EXCEPTION] (state, payload) {
        if(payload) {
            state.hasErrorVOTP = payload
        }else {
            state.hasErrorVOTP.error = false
        }
    },
    [types.SET_AUTH_PWD_EXCEPTION] (state, payload) {
        if(payload) {
            state.hasErrorVOTP = payload
        }else {
            state.hasErrorVOTP.error = false
        }
    },
    [types.SET_AUTH_REFRESING_TOKEN] (state, payload) {
        state.isRefresingToken = payload
    },
}

export default mutations